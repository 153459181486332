export const computed_breadcrumbs = {
    computed: {
        //----------------------------------------
        // ** Alimenta il componente BreadCrumbs
        //----------------------------------------
        breadCrumbs() {
            let pathArray = this.$route.path.split("/");
            
            // ** Tolgo il primo elemento
            pathArray.shift();
            const breadCrumbs = [];
            // needed to handle the intermediary entries for nested vue routes
            let breadcrumb = "";
            let lastIndexFound = 0;
            for (let i = 0; i < pathArray.length; ++i) {
              breadcrumb = `${breadcrumb}${"/"}${pathArray[i]}`;
              // ** Tra i route records che hanno matching con la route
              // ** seleziono quello che ha una proprietà "meta: {breadCrumb: }"
              if (
                this.$route.matched[i] &&
                Object.hasOwnProperty.call(this.$route.matched[i], "meta") &&
                Object.hasOwnProperty.call(this.$route.matched[i].meta, "breadCrumb")
              ) {
                breadCrumbs.push({
                  href:
                    i !== 0 && pathArray[i - (i - lastIndexFound)]
                      ? "/" + pathArray[i - (i - lastIndexFound)] + breadcrumb
                      : breadcrumb,
                  disabled: i + 1 === pathArray.length,
                  text: this.$route.matched[i].meta.breadCrumb || pathArray[i],
                });
                lastIndexFound = i;
                breadcrumb = "";
              }
            }
            return breadCrumbs;
        },
    },
}