<template>
  <!-- color="#00000040" -->
  <div>
    <v-footer fixed color="transparent">
      <v-row no-gutters>
        <!-- <v-col cols="12" class="pt-4" v-if="$vuetify.breakpoint.smAndDown">
          <CardNavigationButtons />
        </v-col> -->
        <v-col cols="2"></v-col>
        <v-col
          class="
            text-center
            white--text
            d-flex
            align-center
            justify-center
            company-font
          "
          cols="8"
        >
          <v-row no-gutters>
            <v-col cols="12" class="white--text">
              <strong>
                <vue-typer
                  :text="[copyrightInfo]"
                  :repeat="0"
                  :shuffle="false"
                  initial-action="typing"
                  :pre-type-delay="100"
                  :type-delay="100"
                  :pre-erase-delay="2000"
                  :erase-delay="250"
                  erase-style="select-all"
                  :erase-on-complete="false"
                  caret-animation="solid"
                  class="white--text avmsquare"
                ></vue-typer>
              </strong>
            </v-col>
            <v-col cols="12" class="py-0 my-0 caption">
              <v-btn
                style="height: 5px"
                class="py-0 my-0"
                @click="openCoookie()"
                color="white"
                small
                text
              >
                <span class="cookie-font">Cookie policy</span>
              </v-btn>
            </v-col>
          </v-row>

          <!-- {{ new Date().getFullYear() }} — <strong>AVMSquare</strong> -->
        </v-col>

        <v-col cols="2" class="d-flex justify-end align-center">
          <v-btn
            href="https://www.facebook.com/AVMSquare-106852851447138"
            target="_blank"
            color="transparent darken-5 "
            fab
            class="my-0 py-0 d-flex align-center justify-center white--text"
            small
            width="20"
            ><v-icon class="py-0 my-0" color="white" small>
              mdi-facebook
            </v-icon>
          </v-btn>

          <v-btn
            href="https://www.instagram.com/avmsquare"
            target="_blank"
            fab
            color="transparent darken-5 "
            class="my-0 py-0 d-flex align-center justify-center white--text"
            small
            width="20"
            ><v-icon class="py-0 my-0" color="white" small>
              mdi-instagram
            </v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </v-footer>
  </div>
</template>

<script>
import { VueTyper } from "vue-typer";
import CardNavigationButtons from "./CardNavigationButtons";
import { EventBus } from "@/main";

// open_cookie_setting
export default {
  components: {
    VueTyper,
    CardNavigationButtons,
  },
  computed: {
    copyrightInfo() {
      if (this.$vuetify.breakpoint.name != "xs") {
        return new Date().getFullYear() + " - AVMSquare Informatica";
      } else {
        return "AVMSquare Informatica";
      }
    },
  },
  methods: {
    openCoookie() {
      EventBus.$emit("open_cookie_setting");
    },
  },
};
</script>

<style>
footer.v-footer.v-sheet.theme--light.v-footer--fixed.transparent {
  background: rgb(2, 0, 36) !important;
  background: linear-gradient(
    0deg,
    rgb(2 17 26 / 95%) 0%,
    rgb(2 17 26 / 95%) 60%,
    rgb(204 0 0 / 0%) 100%
  ) !important;
}

.avmsquare.vue-typer .custom.char {
  color: white !important;
}

.company-font {
  font-family: "JetBrains Mono", monospace;
  font-weight: 400;
  font-size: 0.5rem;
}

.cookie-font {
  font-size: 0.45rem;
}
</style>