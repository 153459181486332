<template>
  <div class="white--text">
    <v-breadcrumbs :items="breadCrumbs" divider=">">
      <template v-slot:item="props">
        <router-link :to="props.item.href" v-if="!props.item.disabled">
          <v-breadcrumbs-item :class="[props.item.disabled && 'disabled']">
            {{ props.item.text }}
          </v-breadcrumbs-item>
        </router-link>
        <v-breadcrumbs-item :class="[props.item.disabled && 'disabled']" v-else>
          {{ props.item.text }}
        </v-breadcrumbs-item>
      </template>
    </v-breadcrumbs>
  </div>
</template>

<script>
export default {};
</script>

<style>
</style>