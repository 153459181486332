<template>
  <div>
    <ul class="cb-slideshow">
      <div>
        <span>Image 01</span>
        <div></div>
      </div>
      <div>
        <span>Image 02</span>
        <div></div>
      </div>
      <div>
        <span>Image 03</span>
        <div></div>
      </div>
      <div>
        <span>Image 04</span>
        <div></div>
      </div>
    </ul>

    <div v-if="!isLandingPage" class="app mt-0 pa-0 gradient" :style="{
      'background-repeat': 'no-repeat',
      'background-attachment': 'fixed',
      'background-image':
        'linear-gradient(to bottom, rgba(0, 0, 0, 0.9) 0%, rgba(0, 0, 0, 0.25) 75%, rgba(255, 255, 252, 0) 100% ), url(' +
        require('./assets/backgrounds/lp01.jpeg') +
        ')',
    }">
      <v-app style="background: none">
        <v-app-bar fixed flat color="transparent" elevation="0" class="text-left" height="80" hide-on-scroll>
          <v-toolbar-title>
            <v-card flat :to="{ name: 'Home' }" color="transparent">
              <img src="./assets/logo/logo_2red_h64.png" srcset="
                  ./assets/logo/logo_2red_h64.png  1x,
                  ./assets/logo/logo_2red_h128.png 2x
                " alt="AVMSquare logo" :to="{ name: 'Home' }" />
            </v-card>
          </v-toolbar-title>
          <v-spacer />
          <v-toolbar-title v-if="!$vuetify.breakpoint.xs">
            <MenuTopButtons />
          </v-toolbar-title>

          <v-app-bar-nav-icon dark @click="drawer.status = !drawer.status"></v-app-bar-nav-icon>
        </v-app-bar>
        <v-main class="ma-0 pa-0">
          <router-view />
        </v-main>
        <dialogCookie v-if="!cookies_decision" />
        <cookiePolicy />
        <LateralMenu :drawer="drawer" />
        <BottomStripe v-if="showBottomStripe" />
      </v-app>
    </div>

    <div v-if="isLandingPage">
      <v-app style="background: none">
        <v-main class="ma-0 pa-0">
          <router-view />
        </v-main>
        <dialogCookie v-if="!cookies_decision" />

      </v-app>
    </div>
  </div>
</template>

<script>
import LateralMenu from "@/components/lateralMenu";

import MenuTopLogo from "./components/MenuTopLogo";
import MenuTopButtons from "./components/MenuTopButtons";
import BottomStripe from "./components/BottomStripe";
import { EventBus } from "./main";
import dialogCookie from "@/components/dialogCookie.vue";

import cookiePolicy from "@/components/cookiePolicy.vue";
import { gtag } from "@/gtag";

import "@/styles/background_animation.css";

function getCookie(cname) {
  let name = cname + "=";
  let decodedCookie = decodeURIComponent(document.cookie);
  let ca = decodedCookie.split(";");
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) == " ") {
      c = c.substring(1);
    }
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
}
export default {
  metaInfo: {
    title: "I nostri servizi",
    titleTemplate: "%s | AVMSquare",
  },
  name: "App",
  data: () => ({
    status_audio: 1,
    // showCookieBanner: false,
    cookies_decision: null,
    drawer: {
      status: false,
    },
  }),
  components: {
    LateralMenu,
    MenuTopLogo,
    MenuTopButtons,
    BottomStripe,
    // CookieConsent,
    dialogCookie,
    cookiePolicy,
  },
  created() { },
  mounted() {
    gtag("consent", "default", {
      ad_storage: "denied",
      analytics_storage: "denied",
      wait_for_update: 2500,
    });
    gtag("config", 'AW-628329712');
    gtag("set", "url_passthrough", true);
    gtag("set", "ads_data_redaction", true);

    setTimeout(() => {
      const lp_cookies_decision = getCookie("lp_cookies_decision");
      const analytics_storage = getCookie("analytics_storage");
      const ad_storage = getCookie("ad_storage");
      console.log("lp_cookies_decision", lp_cookies_decision);
      console.log("analytics_storage", analytics_storage);
      console.log("ad_storage", ad_storage);
      if (!lp_cookies_decision) {
        this.cookie_dialog = true;
        console.log("SHOW COOKIE DIALOG");
      } else {
        if (analytics_storage == "granted") {
          this.consent_mode_grant_analytics();
        } else {
          this.consent_mode_revoke_analytics();
        }
        if (ad_storage == "granted") {
          this.consent_mode_grant_google_ads();
        } else {
          this.consent_mode_revoke_google_ads();
        }
      }
    }, 100);

    this.cookies_decision = getCookie("lp_cookies_decision");

    // console.log(this.cookies_decision);
    // setInterval(() => {
    //   this.showCookieBanner = true;
    // }, 1800);
    this.status_audio = localStorage.getItem("audio");
    // console.log("localStorage get: " + this.status_audio);
    if (this.status_audio != 0 && this.status_audio != 1) {
      this.status_audio = 1;
    }
  },
  methods: {
    consent_mode_grant_analytics() {
      console.log("consent_mode accept analytics");
      gtag("consent", "update", {
        analytics_storage: "granted",
      });
    },
    consent_mode_revoke_analytics() {
      console.log("consent_mode revoke analytics");
      gtag("consent", "update", {
        analytics_storage: "denied",
      });
    },
    consent_mode_grant_google_ads() {
      console.log("consent_mode accept google_ads");
      gtag("consent", "update", {
        ad_storage: "granted",
      });
    },
    consent_mode_revoke_google_ads() {
      console.log("consent_mode revoke google_ads");
      gtag("consent", "update", {
        ad_storage: "denied",
      });
    },
  },
  computed: {
    isLandingPage() {
      if (this.$route.name && this.$route.name.includes("LandingPage_")) {
        return true;
      } else {
        return false;
      }
    },
    showBottomStripe() {
      return true;
      if (this.$route.name == "Contacts") {
        return false;
      }
      return true;
    },
  },
  watch: {
    status_audio(value) {
      console.log("watch audio: " + value);
      this.audio = value;
      localStorage.setItem("audio", value);
      EventBus.$emit("set_audio", value);
    },
  },
};
</script>



<style>
/* .gradient {
  background: linear-gradient(#eb01a5, #d13531) !important;
} */

.v-input--selection-controls__ripple:before {
  position: initial !important;
}

.margin-top {
  margin-top: 80px;
}

.app {
  background-position: center !important;
  background-repeat: no-repeat !important;
  background-size: cover !important;
  background-attachment: fixed !important;
}

.service_link {
  color: black !important;
}

.service_link:link {
  text-decoration: none;
}

.service_link:visited {
  text-decoration: none;
}

.service_link:hover {
  text-decoration: none;
}

.service_link:active {
  text-decoration: none;
}

/* Small screen */
@media only screen and (max-width: 600px) {
  .main_heading {
    font-size: 1.8rem;
    text-shadow: black 3px 3px 6px;
  }

  .main_subheading {
    margin-top: 1rem;
    font-size: 1.8rem;
    text-shadow: black 3px 3px 6px;
    line-height: 1;
  }

  .main_heading_on_white {
    font-size: 1.4rem;
    text-shadow: rgba(0, 0, 0, 0.25) 3px 3px 6px;
    line-height: 1;
  }

  .main_subheading_on_white {
    font-size: 1rem;
    text-shadow: rgba(0, 0, 0, 0.25) 3px 3px 6px;
    line-height: 1;
  }

  .service_undertitle {
    text-shadow: 1px 1px 2px rgb(126, 126, 126);
    /* margin-top: 24px; */
    /* padding-bottom: 48px; */
    font-size: 16px;
  }

  /* style="max-width: 250px; line-height: 1" */
}

/* Large screen */
@media only screen and (min-width: 600px) {

  /*  */
  .main_heading {
    font-size: 2rem;
    text-shadow: black 3px 3px 6px;
  }

  .main_subheading {
    font-size: 1.6rem;
    text-shadow: black 3px 3px 6px;
    line-height: 1;
  }

  .main_heading_on_white {
    font-size: 2rem;
    text-shadow: rgba(0, 0, 0, 0.25) 3px 3px 6px;
    line-height: 1;
    width: 500px;
  }

  .main_subheading_on_white {
    font-size: 1.4rem;
    text-shadow: rgba(0, 0, 0, 0.25) 3px 3px 6px;
    line-height: 1;
    width: 500px;
  }

  .header_separator {
    width: 500px;
  }

  .service_title {
    margin-bottom: 1rem;
  }

  .service_undertitle {
    text-shadow: 1px 1px 2px rgb(126, 126, 126);
    /* margin-top: 24px; */
    /* padding-bottom: 48px; */
    font-size: 18px;
  }

}

.header_separator {
  border-top: 2px solid black;
}

.preservice_title_on_white {
  font-size: 1rem;
}

.service_title {
  font-size: 32px;
  line-height: 1.2;
  /* margin-bottom: 1rem; */
}

.service_subtitle {
  font-size: 24px;
  /* line-height: 1.2; */
  margin-bottom: 1.5rem;
}

.service_separator {
  border-top: 2px solid black;
  display: none;
}

.small_screen_service_image {
  display: flex;
  justify-content: center;
}

.service_image_position {
  margin: auto;
  top: 50px;
}

.rounded_row_white {
  border-top-left-radius: 40px;
  border-bottom-right-radius: 40px;
  background: #ffffffcc;
  box-shadow: 12px 12px 20px 0px black;
}

.rounded_row_black {
  margin-top: 2rem;
  border-top-left-radius: 40px;
  border-bottom-right-radius: 40px;
  background: #00000066;
  box-shadow: 10px 10px 20px black;
}


/* .service_box {
  border-style: solid;
  margin: 0.5rem;
  padding: 0.5rem;
  border-radius: 15px;
} */
</style>