<template>
  <div>
    <!-- <v-card
      :to="{ name: service.link }"
      class="service_border border_shadows"
      :disabled="!service.link"
      flat
      :color="bgColor"
      @mouseenter="setHoverBgColor()"
      @mouseleave="resetHoverBgColor()"
      :max-width="$vuetify.breakpoint.smAndDown ? '' : sm_max_width"
    >
      <v-card-text class="text-center white--text pa-0 ma-0 mb-4 pt-4">
        <h2
          v-bind:class="
            $vuetify.breakpoint.smAndDown ? 'small_screen_card_title' : 'large_screen_card_title'
          "
          v-html="service.title"
        ></h2>
        <v-row no-gutters>
          <v-col cols="12" class="mt-1 d-flex justify-center">
            <v-img
              :max-width="$vuetify.breakpoint.smAndDown ? '60px' : '100px'"
              :max-height="$vuetify.breakpoint.smAndDown ? '60px' : '100px'"
              :src="service.img_src"
              :alt="service.img_alt"
              class="ma-0 pa-0"
            ></v-img>
          </v-col>
          <v-col v-if="service.subtitle" offset="1" cols="10" class="mt-4">
            <hr />
            <span v-html="service.subtitle"></span>
            <hr />
          </v-col>
        </v-row>
      </v-card-text>
    </v-card> -->

    <v-row>
      <v-col cols="12" class="d-flex justify-center pb-0">
        <router-link :to="{ name: service.link }">
          <img
            :src="service.img_src"
            :alt="service.img_alt"
            style="width: 75%; max-width: 150px"
          />
        </router-link>
      </v-col>
      <v-col cols="12" class="text-center pt-0 mb-2">
        <!-- <hr /> -->
        <router-link class="service_link" :to="{ name: service.link }">
          <h3 v-html="service.title"></h3>
        </router-link>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  props: {
    service: {
      type: Object,
    },
    sm_max_width: {
      type: String,
      default: "200px",
    },
  },
  components: {},
  data: () => ({
    bgColor: "#0000002e",
  }),
  methods: {
    setHoverBgColor() {
      console.log("setHoverBgColor");
      this.bgColor = "#00000080";
    },
    resetHoverBgColor() {
      console.log("resetHoverBgColor");
      this.bgColor = "#0000002e";
    },
  },
  mounted() {},
  created() {},
  computed: {},
  watch: {},
};
</script>


<style scoped>

.service_link {
  color: black;
}

.service_link:link {
  text-decoration: none;
}

.service_link:visited {
  text-decoration: none;
}

.service_link:hover {
  text-decoration: none;
}

.service_link:active {
  text-decoration: none;
}
</style>
