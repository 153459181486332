<template>
  <div>
    <v-card
      :to="{ name: service.link }"
      class="service_border border_shadows"
      :disabled="!service.link"
      flat
      :color="bgColor"
      @mouseenter="setHoverBgColor()"
      @mouseleave="resetHoverBgColor()"
      :max-width="$vuetify.breakpoint.smAndDown ? '' : sm_max_width"
    >
      <v-card-text class="text-center white--text pa-0 ma-0 mb-4 pt-4">
        <h2
          v-bind:class="
            $vuetify.breakpoint.smAndDown ? 'small_screen_card_title' : 'large_screen_card_title'
          "
          v-html="service.title"
        ></h2>
        <v-row no-gutters>
          <v-col cols="12" class="mt-1 d-flex justify-center">
            <v-img
              :max-width="$vuetify.breakpoint.smAndDown ? '60px' : '100px'"
              :max-height="$vuetify.breakpoint.smAndDown ? '60px' : '100px'"
              :src="service.img_src"
              :alt="service.img_alt"
              class="ma-0 pa-0"
            ></v-img>
          </v-col>
          <v-col v-if="service.subtitle" offset="1" cols="10" class="mt-4">
            <hr />
            <span v-html="service.subtitle"></span>
            <hr />
          </v-col>
          <!-- <v-col cols="12" class="mb-4">
            <v-btn color="white" :to="{ name: service.link }" block small text
              >Scopri di più</v-btn
            >
          </v-col> -->
        </v-row>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
export default {
  props: {
    service: {
      type: Object,
    },
    sm_max_width: {
      type: String,
      default: "200px",
    },
  },
  components: {},
  data: () => ({
    bgColor: "#0000002e",
  }),
  methods: {
    setHoverBgColor() {
      console.log("setHoverBgColor");
      this.bgColor = "#00000080";
    },
    resetHoverBgColor() {
      console.log("resetHoverBgColor");
      this.bgColor = "#0000002e";
    },
  },
  mounted() {},
  created() {},
  computed: {},
  watch: {},
};
</script>


<style scoped>
.service_border {
  border-width: 2px;
  border-color: rgba(255, 255, 255, 0.5) !important;
  border-style: solid;
  border-radius: 10px;
}

.service_border:hover {
  border-width: 2px;
  border-color: white !important;
  border-style: solid;
  border-radius: 10px;
}

.border_shadows {
  box-shadow: -4px -4px 10px 0px white !important;
}

/* ------------ */

@property --rotate {
  syntax: "<angle>";
  initial-value: 132deg;
  inherits: false;
}

:root {
  --card-height: 65vh;
  --card-width: calc(var(--card-height) / 1.5);
}

.card_glove {
  background: #191c29;
  width: var(--card-width);
  height: var(--card-height);
  padding: 3px;
  position: relative;
  border-radius: 6px;
  justify-content: center;
  align-items: center;
  text-align: center;
  display: flex;
  font-size: 1.5em;
  color: rgb(88 199 250 / 0%);
  cursor: pointer;
  font-family: cursive;
}

.card_glove:hover {
  color: rgb(88 199 250 / 100%);
  transition: color 1s;
}
.card_glove:hover:before,
.card_glove:hover:after {
  animation: none;
  opacity: 0;
}

.card_glove::before {
  content: "";
  width: 104%;
  height: 102%;
  border-radius: 8px;
  background-image: linear-gradient(
    var(--rotate),
    #5ddcff,
    #3c67e3 43%,
    #4e00c2
  );
  position: absolute;
  z-index: -1;
  top: -1%;
  left: -2%;
  animation: spin 2.5s linear infinite;
}

.card_glove::after {
  position: absolute;
  content: "";
  top: calc(var(--card-height) / 6);
  left: 0;
  right: 0;
  z-index: -1;
  height: 100%;
  width: 100%;
  margin: 0 auto;
  transform: scale(0.8);
  filter: blur(calc(var(--card-height) / 6));
  background-image: linear-gradient(
    var(--rotate),
    #5ddcff,
    #3c67e3 43%,
    #4e00c2
  );
  opacity: 1;
  transition: opacity 0.5s;
  animation: spin 2.5s linear infinite;
}

@keyframes spin {
  0% {
    --rotate: 0deg;
  }
  100% {
    --rotate: 360deg;
  }
}

a {
  color: #212534;
  text-decoration: none;
  font-family: sans-serif;
  font-weight: bold;
  margin-top: 2rem;
}


.small_screen_card_title {
font-size: 1rem;;
}

.large_screen_card_title {
font-size: 1.2rem;;
}

</style>
