import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/OurServices.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '',
    name: 'Home',
    component: () => import(/* webpackChunkName: "Home" */ '../views/Home.vue'),
    meta: { breadCrumb: 'Home' },
  },

  // {
  //   path: '/jobs',
  //   name: 'Home',
  //   component: () => import(/* webpackChunkName: "home" */ '../views/PageJobs.vue'),
  //   meta: { breadCrumb: 'Home' },
  // },

  // {
  //   path: '/services/support',
  //   name: 'ServiziAssistenza',
  //   component: () => import(/* webpackChunkName: "OurServices" */ '../views/pages/elencoServiziAssistenza.vue'),
  //   meta: { breadCrumb: 'Home' },
  // },

  // OurServicesAssistenzaConsulenza
  // {
  //   path: '/services/',
  //   name: 'OurServices',
  //   component: () => import(/* webpackChunkName: "OurServices" */ '../views/OurServices.vue'),
  //   meta: { breadCrumb: 'I nostri servizi' },
  // },
  {
    path: '/servizi/supporto',
    name: 'OurServicesAssistenzaConsulenza',
    component: () => import(/* webpackChunkName: "OurServicesAssistenzaConsulenza" */ '../views/OurServicesAssistenzaConsulenza.vue'),
    meta: { breadCrumb: 'Home' },
  },
  {
    path: '/servizi/sviluppo',
    name: 'OurServicesSviluppo',
    component: () => import(/* webpackChunkName: "OurServicesSviluppo" */ '../views/OurServicesSviluppo.vue'),
    meta: { breadCrumb: 'Home' },
  },
  {
    path: '/servizi/supporto/utenti',
    name: 'AssistenzaConsulenza_Utenti',
    component: () => import(/* webpackChunkName: "AssistenzaConsulenza_Utenti" */ '../views/pages/services_details/assistenza.vue'),
    meta: { breadCrumb: 'Assistenza utenti' },
  },
  {
    path: '/servizi/supporto/it',
    name: 'AssistenzaConsulenza_Infrastrutture',
    component: () => import(/* webpackChunkName: "AssistenzaConsulenza_Infrastrutture" */ '../views/pages/services_details/infrastrutture.vue'),
    meta: { breadCrumb: 'Assistenza infrastrutture IT' },
  },
  {
    path: '/servizi/supporto/firewall',
    name: 'AssistenzaConsulenza_Firewall',
    component: () => import(/* webpackChunkName: "AssistenzaConsulenza_Firewall" */ '../views/pages/services_details/firewall.vue'),
    meta: { breadCrumb: 'Firewall' },
  },
  {
    path: '/servizi/supporto/backup',
    name: 'AssistenzaConsulenza_Backup',
    component: () => import(/* webpackChunkName: "AssistenzaConsulenza_Backup" */ '../views/pages/services_details/backup.vue'),
    meta: { breadCrumb: 'Backup' },
  },
  {
    path: '/servizi/supporto/cloud',
    name: 'AssistenzaConsulenza_Cloud',
    component: () => import(/* webpackChunkName: "AssistenzaConsulenza_Cloud" */ '../views/pages/services_details/cloud.vue'),
    meta: { breadCrumb: 'Cloud' },
  },
  {
    path: '/servizi/supporto/sicurezza',
    name: 'AssistenzaConsulenza_Sicurezza',
    component: () => import(/* webpackChunkName: "AssistenzaConsulenza_Sicurezza" */ '../views/pages/services_details/sicurezza.vue'),
    meta: { breadCrumb: 'Sicurezza' },
  },

  // ---------------------------------
  {
    path: '/servizi/sviluppo/web',
    name: 'Sviluppo_SitiWeb',
    component: () => import(/* webpackChunkName: "SviluppoSitiWeb" */ '../views/pages/dev_details/sitiweb.vue'),
    meta: { breadCrumb: 'Siti Web' },
  },
  // ---------------------------------

  {
    path: '/scopri/tipologia_siti_web',
    name: 'InDeep_WebsiteTypes',
    component: () => import(/* webpackChunkName: "InDeep_WebsiteTypes" */ '../views/pages/inDeep/website_types.vue'),
    // meta: { breadCrumb: 'Siti Web' },
  },
  // ---------------------------------
  // LANDINGS

  {
    path: '/ad/web',
    name: 'LandingPage_SitiWeb',
    component: () => import(/* webpackChunkName: "LandingPage_SitiWeb" */ '../views/pages/landings/sitiweb.vue'),
    // meta: { breadCrumb: 'Siti Web' },
  },

  {
    path: '/ad/thankyou',
    name: 'LandingPage_Thankyou',
    component: () => import(/* webpackChunkName: "LandingPage_Thankyou" */ '../views/pages/landings/contact_thankyou.vue'),
  },

  

  // ---------------------------------

  {
    path: '/about/me',
    name: 'About',
    component: () => import(/* webpackChunkName: "About" */ '../views/pages/about.vue'),
    meta: { breadCrumb: 'Chi sono' },
  },
  {
    path: '/cookie',
    name: 'Cookie',
    component: () => import(/* webpackChunkName: "cookie" */ '../views/CookieLaw.vue'),
    meta: { breadCrumb: 'Cookie' },
  },
  {
    path: '/contattaci',
    name: 'Contacts',
    component: () => import(/* webpackChunkName: "contacts" */ '../views/Contacts.vue'),
    meta: { breadCrumb: 'Contacts' },
  },
  {
    path: '/contacts/:topic/',
    name: 'Contacts',
    component: () => import(/* webpackChunkName: "contacts" */ '../views/Contacts.vue'),
    meta: { breadCrumb: 'Contacts' },
  },
  {
    path: '/uc',
    name: 'UnderConstruction',
    component: () => import(/* webpackChunkName: "UnderConstruction" */ '../views/UnderConstruction.vue'),
    meta: { breadCrumb: 'UnderConstruction' },
  },
  {
    path: '*',
    name: 'Page404',
    component: () => import(/* webpackChunkName: "Page404" */ '../views/Page404.vue'),
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    return {
      x: 0,
      y: 0,
      behavior: 'smooth',
    };
  }
})

export default router
