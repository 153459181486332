<template>
  <div>
    <div class="bottom-gonext">
      <!-- <div class="d-flex justify-space-around mb-6"> -->
      <v-row no-gutters class="mb-2 justify-center">
        <v-col cols="3" class="d-flex justify-center">
          <v-card
            tile
            elevation="10"
            class="next-button button-jobs d-flex align-center justify-center"
            :to="{ name: 'OurServicesAssistenzaConsulenza' }"
            v-ripple="{ class: 'blue--text' }"
            v-bind:class="cardStyle('our_services')"
            @mouseover="playHoverSound('our_services')"
            @click="playClickSound('our_services')"
            :width="cardWidth"
            :height="cardHeight"
          >
            <v-row no-gutters>
              <v-col cols="12" class="text-center button-gonext-title">
                Servizi
              </v-col>
              <v-col cols="12" class="text-center">
                <v-icon
                  :x-large="$vuetify.breakpoint.name != 'xs'"
                  :small="$vuetify.breakpoint.name == 'xs'"
                  color="#e2e2e2"
                  class="button-gonext-icon"
                  >mdi-run-fast</v-icon
                >
              </v-col>
            </v-row>
          </v-card>
        </v-col>

        <!-- <v-col cols="3" class="d-flex justify-center">
          <v-card
            class="next-button button-jobs d-flex align-center justify-center"
            :to="{ name: 'Target' }"
            v-ripple="{ class: 'blue--text' }"
            v-bind:class="cardStyle('obiettivi')"
            @mouseover="playHoverSound('obiettivi')"
            @click="playClickSound('obiettivi')"
            :width="cardWidth"
            :height="cardHeight"
            elevation="10"
          >
            <v-row no-gutters>
              <v-col cols="12" class="text-center button-gonext-title">
                Obiettivi
              </v-col>
              <v-col cols="12" class="text-center">
                <v-icon
                  :x-large="$vuetify.breakpoint.name != 'xs'"
                  :small="$vuetify.breakpoint.name == 'xs'"
                  color="#e2e2e2"
                  class="button-gonext-icon"
                  >mdi-target</v-icon
                >
              </v-col>
            </v-row>
          </v-card>
        </v-col> -->

        <v-col cols="3" class="d-flex justify-center">
          <v-card
            tile
            elevation="10"
            class="next-button button-jobs d-flex align-center justify-center"
            :to="{ name: 'Contacts' }"
            v-ripple="{ class: 'blue--text' }"
            v-bind:class="cardStyle('contacts')"
            @mouseover="playHoverSound('contacts')"
            @click="playClickSound('contacts')"
            :width="cardWidth"
            :height="cardHeight"
          >
            <v-row no-gutters>
              <v-col cols="12" class="text-center button-gonext-title">
                Contatti
              </v-col>
              <v-col cols="12" class="text-center">
                <v-icon
                  :x-large="$vuetify.breakpoint.name != 'xs'"
                  :small="$vuetify.breakpoint.name == 'xs'"
                  color="#e2e2e2"
                  class="button-gonext-icon"
                  >mdi-email-edit</v-icon
                >
              </v-col>
            </v-row>
          </v-card>
        </v-col>

        <v-col cols="3" class="d-flex justify-center">
          <v-card
            tile
            elevation="10"
            class="next-button button-jobs d-flex align-center justify-center"
            :to="{ name: 'About' }"
            v-ripple="{ class: 'blue--text' }"
            v-bind:class="cardStyle('about')"
            @mouseover="playHoverSound('about')"
            @click="playClickSound('about')"
            :width="cardWidth"
            :height="cardHeight"
          >
            <v-row no-gutters>
              <v-col cols="12" class="text-center button-gonext-title">
                Chi sono?
              </v-col>
              <v-col cols="12" class="text-center">
                <v-icon
                  :x-large="$vuetify.breakpoint.name != 'xs'"
                  :small="$vuetify.breakpoint.name == 'xs'"
                  color="#e2e2e2"
                  class="button-gonext-icon"
                  >mdi-head-question</v-icon
                >
              </v-col>
            </v-row>
          </v-card>
        </v-col>

        <!-- <v-col cols="4" class="d-flex justify-center">
          <v-card
            tile
            elevation="10"
            class="next-button button-jobs d-flex align-center justify-center"
            :to="{ name: 'Experiences' }"
            v-ripple="{ class: 'blue--text' }"
            v-bind:class="cardStyle('esperienze')"
            @mouseover="playHoverSound()"
            @click="playClickSound('esperienze')"
            :width="cardWidth"
            :height="cardHeight"
          >
            <v-row no-gutters>
              <v-col cols="12" class="text-center button-gonext-title">
                Esperienze
              </v-col>
              <v-col cols="12" class="text-center">
                <v-icon
                  :x-large="$vuetify.breakpoint.name != 'xs'"
                  :small="$vuetify.breakpoint.name == 'xs'"
                  color="#e2e2e2"
                  class="button-gonext-icon"
                  >mdi-podcast</v-icon
                >
              </v-col>
            </v-row>
          </v-card>
        </v-col> -->
      </v-row>
    </div>
    <!-- </div> -->
  </div>
</template>

<script>
import { Howl, Howler } from "howler";
import { EventBus } from "../main";

export default {
  data: () => ({
    what_show: "",
    audio: 1,
    last_hover_sound: null,
  }),
  components: {},
  computed: {
    cardWidth() {
      if (this.$vuetify.breakpoint.name != "xs") {
        return 100;
      } else {
        return 60;
      }
    },
    cardHeight() {
      if (this.$vuetify.breakpoint.name != "xs") {
        return 100;
      } else {
        return 60;
      }
    },
  },
  methods: {
    playHoverSound(what) {
      if (this.audio == 1 && this.last_hover_sound != what) {
        var sound = new Howl({
          // src: ["./sound/button-20.mp3"],
          src: require("@/assets/audio/button-35.mp3"),
          volume: 0.1,
        });

        sound.play();
        this.last_hover_sound = what;
      }
    },
    playClickSound(what) {
      console.log("playClickSound " + this.audio);
      this.what_show = what;
      if (this.audio == 1) {
        var sound = new Howl({
          src: require("@/assets/audio/button-17.mp3"),
          volume: 0.1,
        });
        sound.play();
      }
    },
    isSelected(what) {
      if (what == this.what_show) {
        return "card-selected";
      }
    },
    cardStyle(what) {
      // console.log(what);
      // console.log("route name " + this.$route.name);
      switch (this.$route.name) {
        case "Target":
          this.what_show = "obiettivi";
          break;
        // case "Home":
        //   this.what_show = "our_services";
        //   break;
        case "OurServices":
          this.what_show = "our_services";
          break;

        case "OurServicesAssistenzaConsulenza":
          this.what_show = "our_services";
          break;

        case "Contacts":
          this.what_show = "contacts";
          break;
        case "About":
          this.what_show = "about";
          break;
        default:
          this.what_show = "";
          break;
      }

      if (this.$route.name == "Target") {
        this.what_show = "obiettivi";
      }
      if (this.$route.name == "Target") {
        this.what_show = "obiettivi";
      }
      if (this.$route.name == "Target") {
        this.what_show = "obiettivi";
      }
      let classes = "";
      if (what == this.what_show) {
        classes += " card-selected";
      }
      if (this.$vuetify.breakpoint.name != "xs") {
        classes += " rounded-xl";
      } else {
        classes += " rounded-sm";
      }
      // console.log(classes);
      return classes;
    },
  },
  mounted() {
    EventBus.$on("set_audio", (value) => {
      this.audio = value;
      console.log("event set_audio: " + this.audio);
    });
  },
};
</script>

<style scoped>
.card-selected .text-center.button-gonext-title.col {
  color: rgb(49, 49, 49) !important;
  text-shadow: 0px 0px 1px white;
}

.card-selected .v-icon.notranslate.button-gonext-icon {
  color: rgb(49, 49, 49) !important;
}

.card-selected .next-button {
  color: rgb(38, 38, 38) !important;
}

a.next-button.card-selected {
  background: #ffffff !important;
}

.button-gonext-title {
  font-family: "Fjalla One", sans-serif;
  color: #e2e2e2 !important;
}

.next-button {
  width: 100px;
  height: 100px;
  background-color: rgb(36, 36, 36) !important;
  color: white;
}

.next-button:hover .button-gonext-title {
  color: #ffffff !important;
}

.next-button:hover .button-gonext-icon {
  color: white !important;
  animation-name: color_fade-title;
  animation-duration: 1s;
}

@keyframes color_fade-title {
  0% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}

@keyframes color_fade-boxshadow {
  0% {
    box-shadow: 0px 0px 24px rgba(255, 255, 255, 0);
  }
  100% {
    box-shadow: 0px 0px 24px white;
  }
}

/* Mobile breakpoint */
@media only screen and (max-width: 600px) {
  .button-gonext-title {
    font-size: 0.8rem;
  }
}
</style>