<template>
  <div
    class="home"
    v-bind:class="$vuetify.breakpoint.smAndDown ? 'mx-2' : ''"
    style="margin-bottom: 50px; margin-top: 80px"
  >
    <v-row align="center" justify="space-around" class="mt-12">
      <v-col cols="12" sm="4"  class="py-0">
        <cardService :service="service_assistenza" sm_max_width="300px" />
      </v-col>
      <v-col cols="12"  sm="4" class="py-0">
        <cardService :service="service_sviluppo" sm_max_width="300px" />
      </v-col>
    </v-row>
  </div>
</template>


<script>
import BreadCrumbs from "../components/BreadCrumbs";
import cardService from "@/components/cardService";
import CookieLawVue from "./CookieLaw.vue";

export default {
  metaInfo: {
    title: "Servizi offerti",
    meta: [
      {
        name: "description",
        content: "Alcune tipologie di servizi offerti ai nostri clienti.",
      },
    ],
  },
  name: "Home",
  components: {
    cardService,
  },
  props: ["what"],
  data: () => ({
    // services: services,
    service_assistenza: {
      title: "Assistenza & Consulenza<br/>Informatica",
      // subtitle: "Supporto utenti infrastrutture di rete e sicurezza",
      link: "OurServicesAssistenzaConsulenza",
      img_src: require("@/assets/imgs/soluzioni_assistenza_w.svg"),
      img_alt: "Icona assistenza",
    },
    service_sviluppo: {
      title: "Analisi & Sviluppo<br/>software",
      // subtitle: "Analisi, progettazione e sviluppo",
      link: "OurServicesSviluppo",
      img_src: require("@/assets/imgs/coding_guy_w.svg"),
      img_alt: "Icona sviluppo software",
    },
  }),
  created() {},
  mounted() {
    // analytics.logEvent("openHome");
  },
  computed: {},
  watch: {},
};
</script>


