export const computed_style = {
    computed: {
        main_card_width() {
            return {
                cols: 12,
                sm: 12,
                md: 10,
                lg: 8,
                xl: 6
            }
        },
    },
}