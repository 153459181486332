<template>
  <div>
    <v-navigation-drawer v-model="drawer.status" fixed temporary right>
      <v-list dense>
        <v-list-item class="d-flex justify-center mt-4 mb-12">
          <img
            src="@/assets/logo/logo_blue_2red_h64.png"
            srcset="
              @/assets/logo/logo_blue_2red_h64.png  1x,
              @/assets/logo/logo_blue_2red_h128.png 2x
            "
            alt="AVMSquare logo"
            :to="{ name: 'Home' }"
          />
        </v-list-item>

        <v-list-item >
          <span class="list_title"> I nostri servizi </span>
        </v-list-item>

        <v-list-group>
          <template v-slot:activator>
            <v-list-item-title class="caption">
              Assistenza & Consulenza
            </v-list-item-title>
          </template>

          <v-list-item
            link
            :to="{ name: servizio.link }"
            v-for="servizio in services_assistenza_consulenza"
            :key="servizio.title"
          >
            <v-list-item-icon class="mr-0">
              <v-img :src="servizio.img_src" contain max-width="64"></v-img>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-subtitle class="caption">
                <span v-html="servizio.menu_item"></span>
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-list-group>


        <v-list-group>
          <template v-slot:activator>
            <v-list-item-title class="caption">
              WEB & Sviluppo
            </v-list-item-title>
          </template>

          <v-list-item
            link
            :to="{ name: servizio.link }"
            v-for="servizio in services_sviluppo"
            :key="servizio.title"
          >
            <v-list-item-icon class="mr-0">
              <v-img :src="servizio.img_src" contain max-width="64"></v-img>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-subtitle class="caption">
                <span v-html="servizio.menu_item"></span>
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-list-group>



      </v-list>
      <template v-slot:append>

        <v-list-item class="mt-0" :to="{ name: 'Contacts' }">
          <span  class="list_title"> Contattaci </span>
        </v-list-item>

        <v-list-item :to="{ name: 'About' }">
          <span  class="list_title">Chi siamo? </span>
        </v-list-item>
<!-- 
        <v-row>
          <v-col
            cols="12"
            class="text-center"
          >

          </v-col>
          <v-col cols="12" class="ml-3">
            <span style="color: #5e5e5e" class="caption">
              Build: wqe
            </span>
          </v-col>
        </v-row> -->
      </template>

    </v-navigation-drawer>
  </div>
</template>

<script>
import { services_assistenza_consulenza } from "@/views/OurServicesAssistenzaConsulenza.vue";
import { services_sviluppo } from "@/views/OurServicesSviluppo.vue";

export default {
  props: {
    drawer: {
      type: Object,
    },
  },
  components: {},
  data: () => ({
    services_assistenza_consulenza: services_assistenza_consulenza,
    services_sviluppo: services_sviluppo,
  }),
  methods: {},
  mounted() {},
  created() {},
  computed: {},
  watch: {},
};
</script>

<style scoped>
.deep_menu {
  padding-left: 40px !important;
}

.list_title {
  font-weight: bold;
  text-align: center;
}
</style>