var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_vm._m(0),(!_vm.isLandingPage)?_c('div',{staticClass:"app mt-0 pa-0 gradient",style:({
    'background-repeat': 'no-repeat',
    'background-attachment': 'fixed',
    'background-image':
      'linear-gradient(to bottom, rgba(0, 0, 0, 0.9) 0%, rgba(0, 0, 0, 0.25) 75%, rgba(255, 255, 252, 0) 100% ), url(' +
      require('./assets/backgrounds/lp01.jpeg') +
      ')',
  })},[_c('v-app',{staticStyle:{"background":"none"}},[_c('v-app-bar',{staticClass:"text-left",attrs:{"fixed":"","flat":"","color":"transparent","elevation":"0","height":"80","hide-on-scroll":""}},[_c('v-toolbar-title',[_c('v-card',{attrs:{"flat":"","to":{ name: 'Home' },"color":"transparent"}},[_c('img',{attrs:{"src":require("./assets/logo/logo_2red_h64.png"),"srcset":require("./assets/logo/logo_2red_h64.png") + " 1x, " + require("./assets/logo/logo_2red_h128.png") + " 2x","alt":"AVMSquare logo","to":{ name: 'Home' }}})])],1),_c('v-spacer'),(!_vm.$vuetify.breakpoint.xs)?_c('v-toolbar-title',[_c('MenuTopButtons')],1):_vm._e(),_c('v-app-bar-nav-icon',{attrs:{"dark":""},on:{"click":function($event){_vm.drawer.status = !_vm.drawer.status}}})],1),_c('v-main',{staticClass:"ma-0 pa-0"},[_c('router-view')],1),(!_vm.cookies_decision)?_c('dialogCookie'):_vm._e(),_c('cookiePolicy'),_c('LateralMenu',{attrs:{"drawer":_vm.drawer}}),(_vm.showBottomStripe)?_c('BottomStripe'):_vm._e()],1)],1):_vm._e(),(_vm.isLandingPage)?_c('div',[_c('v-app',{staticStyle:{"background":"none"}},[_c('v-main',{staticClass:"ma-0 pa-0"},[_c('router-view')],1),(!_vm.cookies_decision)?_c('dialogCookie'):_vm._e()],1)],1):_vm._e()])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ul',{staticClass:"cb-slideshow"},[_c('div',[_c('span',[_vm._v("Image 01")]),_c('div')]),_c('div',[_c('span',[_vm._v("Image 02")]),_c('div')]),_c('div',[_c('span',[_vm._v("Image 03")]),_c('div')]),_c('div',[_c('span',[_vm._v("Image 04")]),_c('div')])])}]

export { render, staticRenderFns }