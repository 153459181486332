<template>
  <!-- <div class="menu-text white--text"> -->
  <!-- Logo is coming... -->
  <!-- <v-app-bar-nav-icon> -->
  <v-img
    :src="require('../assets/logo/l300.png')"
    class="pl-0"
    contain
    max-height="100"
    alt="AVMSquare logo"
  />
  <!-- </v-app-bar-nav-icon> -->
  <!-- </div> -->
</template>

<script>
export default {
  data: () => ({}),
};
</script>

<style scoped>
.menu-text {
  font-family: "Fjalla One", sans-serif;
}
a.router-link-exact-active.router-link-active.v-card.v-card--link.v-sheet.theme--light.elevation-0.transparent:hover {
  color: blue !important;
  background: black;
}
</style>