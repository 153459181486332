<template>
  <div
    class="menu-text white--text d-flex align-center justify-center"
    style="width: 100%"
  >
    <v-list-item
      class="d-inline-block d-flex align-center"
      color="transparent"
      elevation="0"
      :to="{ name: 'Home' }"
      exact
    >
      <span class="menu_text white--text">Home</span>
    </v-list-item>
    |
    <v-list-item
      class="d-inline-block d-flex align-center"
      color="transparent"
      elevation="0"
      :to="{ name: 'OurServicesAssistenzaConsulenza' }"
      exact
    >
      <span class="menu_text white--text">Servizi</span>
    </v-list-item>
    |
    <v-list-item
      class="d-inline-block d-flex align-center"
      color="transparent"
      elevation="0"
      :to="{ name: 'OurServicesSviluppo' }"
      exact
    >
      <span class="menu_text white--text">Sviluppo</span>
    </v-list-item>
    |
    <v-list-item
      class="d-inline-block d-flex align-center"
      color="transparent"
      elevation="0"
      :to="{ name: 'Contacts' }"
      exact
    >
      <span>
        <v-icon color="white">mdi-email-edit</v-icon>
      </span>
    </v-list-item>
    |
    <v-list-item
      class="d-inline-block d-flex align-center"
      color="transparent"
      elevation="0"
      v-if="fullscreen_supported"
      @click="togglefullScreen()"
    >
      <v-icon color="white">mdi-fullscreen</v-icon>
    </v-list-item>
  </div>
</template>

<script>
export default {
  data: () => ({
    fullscreen: false,
    fullscreen_supported: false,
  }),
  components: {},
  computed: {},
  mounted() {
    if (document.fullscreenEnabled) {
      this.fullscreen_supported = true;
    }
  },
  methods: {
    togglefullScreen() {
      this.fullscreen = !this.fullscreen;
      if (this.fullscreen) {
        console.log("go fullscreen");
        document.documentElement.requestFullscreen();
      } else {
        document.exitFullscreen();
      }
    },
  },
};
</script>

<style scoped>
.menu-text {
  /* font-family: "Fjalla One", sans-serif; */
}
/* a.router-link-exact-active.router-link-active.v-card.v-card--link.v-sheet.theme--light.elevation-0.transparent:hover {
  color: blue !important;
  background: black;
} */

.menu_text {
  font-size: 0.8rem;
}

.d-inline-block.v-list-item--active.v-list-item.v-list-item--link.theme--light.transparent--text
  span {
  color: rgb(0, 229, 255) !important;
  /* font-weight: bold; */
  border-bottom-style: solid;
  border-bottom-color: rgb(0, 229, 255) !important;
  /* background: white; */
}

.d-inline-block.v-list-item--active.v-list-item.v-list-item--link.theme--light.transparent--text
  i {
  color: rgb(0, 229, 255) !important;
  font-weight: bold;
    border-bottom-style: none;

}
</style>