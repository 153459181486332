<template>
  <div>
    <v-dialog
      v-model="cookie_policy_dialog"
      no-click-animation
      :overlay-opacity="0.8"
      :overlay="true"
      transition="dialog-transition"
      max-width="600"
      persistent
    >
      <v-card>
        <v-card-text class="py-8">
          <h2>Cookie Policy</h2>

          <p>
            I cookie sono piccoli file di testo che possono essere utilizzati
            dai siti web per rendere più efficiente l'esperienza per l'utente.La
            legge afferma che possiamo memorizzare i cookie sul suo dispositivo
            se sono strettamente necessari per il funzionamento di questo sito,
            oppure se sono cookie statistici raccolti in forma anonima. Per
            tutti gli altri tipi di cookie abbiamo bisogno del suo permesso.
            Questo sito utilizza diversi tipi di cookie. Alcuni cookie sono
            collocati da servizi di terzi che compaiono sulle nostre pagine. In
            qualsiasi momento è possibile modificare o revocare il proprio
            consenso nella sezione Cookie che trova a piè di pagina.
          </p>

          <h3>Elenco cookie utilizzati</h3>

          Per un elenco esaustivo e aggiornato di ogni singolo cookie impiegato
          rimandiamo alle pagine di dettaglio messe a disposizione dai fornitori
          terzi di servizi:

          <li>
            Google Analytics:
            <a
              target="_blank"
              href="https://developers.google.com/analytics/devguides/collection/analyticsjs/cookie-usage"
              >(Link)</a
            >
          </li>
          <li>
            Linkedin:
            <a
              target="_blank"
              href="https://it.linkedin.com/legal/l/cookie-table?"
              >(Link)</a
            >
          </li>

          <!-- <v-simple-table>
            <thead>
              <tr>
                <th>Nome</th>
                <th>Fornitore</th>
                <th>Dominio</th>
                <th>Descrizione</th>
                <th class="text-center">Validità</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="cookie in cookies" :key="cookie">
                <td>{{ cookie.name }}</td>
                <td>{{ cookie.provider }}</td>
                <td>{{ cookie.domain }}</td>
                <td>{{ cookie.description }}</td>
                <td>{{ cookie.expiration }}</td>
              </tr>
            </tbody>
          </v-simple-table> -->

          <div>
            <h3 class="mt-8">
              Come disabilitare i cookie dai principali browser?
            </h3>

            La maggior parte dei browser (Internet Explorer, Firefox, Chrome,
            ecc.) sono configurati per accettare i cookie. I cookie memorizzati
            sul disco fisso del tuo dispositivo possono comunque essere
            cancellati ed è inoltre possibile disabilitare i cookie seguendo le
            indicazioni fornite dai principali browser; ai link seguenti le
            indicazioni dei principali browser:
            <li>
              Chrome
              <a
                href="https://support.google.com/chrome/answer/95647?hl=it Firefox:
            https://support.mozilla.org/it/kb/Attivare%20e%20disattivare%20i%20cookie"
                >(Link)</a
              >
            </li>

            <li>
              Internet Explorer
              <a
                href="http://windows.microsoft.com/it-it/internet-explorer/delete-manage-cookies#ie=ie-10
            Opera: http://help.opera.com/Windows/10.00/it/cookies.html"
                >(Link)</a
              >
            </li>

            <li>
              Safari
              <a href="https://www.apple.com/legal/privacy/it/cookies/"
                >(Link)</a
              >
            </li>
          </div>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn text width="150" @click="cookie_policy_dialog = false">Chiudi</v-btn>
          <v-spacer />
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { EventBus } from "@/main";

export default {
  props: {},
  components: {},
  data: () => ({
    cookie_policy_dialog: false,

    // cookies: [
    //   {
    //     name: "lang",
    //     provider: "Linkedin",
    //     domain: ".linkedin.com",
    //     description:
    //       "Utilizzato per ricordare le impostazioni della lingua dell’utentee fare in modo che le pagine di LinkedIn.com siano visualizzate nella lingua selezionata dall’utente nelle impostazioni personali.",
    //     expiration: "Sessione",
    //   },
    //   {
    //     name: "bcookie",
    //     provider: "Linkedin",
    //     domain: ".linkedin.com",
    //     description:
    //       "Cookie identificativo del browser per identificare in modo univoco i dispositivi su cui viene effettuato l’accesso a LinkedIn per rilevare abusi sulla piattaforma",
    //     expiration: "2 anni",
    //   },
    //   {
    //     name: "AnalyticsSyncHistory",
    //     provider: "Linkedin",
    //     domain: ".linkedin.com",
    //     description:
    //       "Utilizzato per memorizzare le informazioni sull’ora in cui ha avuto luogo una sincronizzazione con il cookie lms_analytics per gli utenti nei Paesi designati",
    //     expiration: "30 giorni",
    //   },
    //   {
    //     name: "UserMatchHistory",
    //     provider: "Linkedin",
    //     domain: ".linkedin.com",
    //     description: "LinkedIn Ads ID syncing",
    //     expiration: "30 giorni",
    //   },
    //   {
    //     name: "li_gc",
    //     provider: "Linkedin",
    //     domain: ".linkedin.com",
    //     description:
    //       "Utilizzato per memorizzare il consenso degli ospiti in relazione all’utilizzo dei cookie per scopi non essenziali",
    //     expiration: "2 anni",
    //   },
    //   {
    //     name: "lidc",
    //     provider: "Linkedin",
    //     domain: ".linkedin.com",
    //     description: "Per ottimizzare la selezione del data center",
    //     expiration: "24 ore",
    //   },
    //   {
    //     name: "_ga",
    //     provider: "Google",
    //     domain: ".google.com",
    //     description:
    //       "Permette di numerare le visite del sito e quindi conoscere l’efficacia dello stesso.",
    //     expiration: "24 ore",
    //   },

    //   //
    //   {
    //     name: "AMCVS_14215E3D5995C57C0A495C55%40AdobeOrg",
    //     provider: "Linkedin",
    //     domain: ".linkedin.com",
    //     description:
    //       "Indica l’inizio di una sessione per Adobe Experience Cloud",
    //     expiration: "Sessione",
    //   },
    //   {
    //     name: "AMCV_14215E3D5995C57C0A495C55%40AdobeOrg",
    //     provider: "Linkedin",
    //     domain: ".linkedin.com",
    //     description: "Identificatore unico per Adobe Experience Cloud",
    //     expiration: "180 giorni",
    //   },
    //   {
    //     name: "AnalyticsSyncHistory",
    //     provider: "Linkedin",
    //     domain: ".linkedin.com",
    //     description:
    //       "Utilizzato per memorizzare le informazioni sull’ora in cui ha avuto luogo una sincronizzazione con il cookie lms_analytics per gli utenti nei Paesi designati",
    //     expiration: "30 giorni",
    //   },
    //   {
    //     name: "SID",
    //     provider: "Linkedin",
    //     domain: ".linkedin.com",
    //     description:
    //       "Utilizzato per stabilire cosa sta facendo un visitatore prima del passaggio su un microsito di LinkedIn",
    //     expiration: "1 anno",
    //   },
    //   {
    //     name: "UserMatchHistory",
    //     provider: "Linkedin",
    //     domain: "linkedin.com",
    //     description: "LinkedIn Ads ID syncing",
    //     expiration: "30 giorni",
    //   },
    //   {
    //     name: "VID",
    //     provider: "Linkedin",
    //     domain: ".linkedin.com",
    //     description:
    //       "ID associato al visitatore di un microsito di LinkedIn che viene utilizzato per stabilire le conversioni per scopi di acquisizione di contatti",
    //     expiration: "1 anno",
    //   },
    //   {
    //     name: "aam_uuid",
    //     provider: "Linkedin",
    //     domain: ".linkedin.com",
    //     description:
    //       "Impostato per la sincronizzazione dell’ID per Adobe Audience Manager",
    //     expiration: "30 giorni",
    //   },

    //   {
    //     name: "at_check",
    //     provider: "Linkedin",
    //     domain: ".linkedin.com",
    //     description:
    //       "Utilizzato per determinare se un visitatore ha accettato l’uso dei cookie per Adobe Target",
    //     expiration: "Sessione",
    //   },
    // ],
  }),
  methods: {},
  mounted() {
    EventBus.$on("open_cookie_policy", () => {
      this.cookie_policy_dialog = true;
    });
  },
  created() {},
  computed: {},
  watch: {},
};
</script>

<style>
</style>