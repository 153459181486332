<template>
  <div
    class="home"
    v-bind:class="$vuetify.breakpoint.smAndDown ? 'mx-2' : 'mx-0 px-0'"
    style="margin-bottom: 50px; margin-top: 80px"
  >
    <v-row no-gutters justify="center" class="mt-12 text-center">
      <v-col v-bind="main_card_width">
        <v-card flat color="transparent" class="pa-2">
          <v-card-text class="text-center white--text">
            <v-row class="rounded_row_white black--text pa-6 pb-12">
              <v-col cols="12" class="text-left d-flex align-center">
                <div>
                  <h1 class="main_heading_on_white">
                    Assistenza, consulenza e soluzioni informatiche
                  </h1>
                  <hr class="my-2 header_separator" />
                  <h2 class="main_subheading_on_white mt-0">
                    Scopri i servizi dedicati alle aziende e agli utenti privati
                  </h2>
                </div>
              </v-col>
              <v-col
                data-aos="fade-right"
                cols="12"
                offset-sm="3"
                sm="6"
                offset-lg="2"
                lg="8"
                class="black--text"
              >
                <!--  -->
                <v-row>
                  <v-col
                    cols="12"
                    sm="6"
                    v-for="(service, index) in services"
                    :key="index"
                    class="d-flex justify-center pb-0 px-2 service_box"
                    :data-aos="aos_effect(index)"
                    :data-aos-delay="150 * index"
                    style="min-height: 200px"
                  >
                    <v-row no-gutters>
                      <v-col
                        cols="12"
                        class="pb-0 d-flex justify-center align-center"
                      >
                        <router-link
                          :to="{ name: service.link }"
                          style="display: flex"
                        >
                          <v-img
                            contain
                            :max-height="
                              $vuetify.breakpoint.smAndDown ? 100 : 150
                            "
                            :max-width="
                              $vuetify.breakpoint.smAndDown ? 100 : 150
                            "
                            :src="service.img_src"
                            :alt="service.img_alt"
                            style="
                              width: 75%;
                              max-width: 150px;
                              max-height: 80px;
                            "
                          />
                        </router-link>
                      </v-col>
                      <v-col cols="12" style="height: 60px">
                        <hr class="service_separator" />
                        <router-link
                          class="service_link"
                          :to="{ name: service.link }"
                        >
                          <h3
                            class="service_undertitle"
                            v-html="service.menu_item"
                          ></h3>
                        </router-link>
                      </v-col>
                    </v-row>
                    <!--  -->
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
            <!--  -->
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>


<script>
import cardService from "@/components/cardService";
import cardService2 from "@/components/cardService2";
import CookieLawVue from "./CookieLaw.vue";
export const services_assistenza_consulenza = [
  {
    title: "Supporto<br/>informatico",
    menu_item: "Assistenza",
    // subtitle: "Supporto risoluzione problemi",
    link: "AssistenzaConsulenza_Utenti",
    // img_src: require("@/assets/imgs/assistenza.svg"),
    img_src: require("@/assets/imgs/pages/support.png"),
    img_alt: "Icona supporto informatico",
  },

  {
    title: "Infrastrutture<br/>aziendali",
    menu_item: "Consulenza IT",
    // subtitle: "Attività IT",
    link: "AssistenzaConsulenza_Infrastrutture",
    // img_src: require("@/assets/imgs/infrastrutture.svg"),
    img_src: require("@/assets/imgs/pages/infrastructure5.png"),
    img_alt: "Icona infrastrutture aziendali",
  },

  {
    title: "Firewall<br/>&nbsp;",
    menu_item: "Firewall",
    // subtitle: "Sicurezza delle reti",
    link: "AssistenzaConsulenza_Firewall",
    // img_src: require("@/assets/imgs/firewall.svg"),
    img_src: require("@/assets/imgs/pages/firewall.png"),
    img_alt: "Icona firewall",
  },
  {
    title: "Backup<br/>&nbsp;",
    menu_item: "Backup",
    // subtitle: "Sicurezza dei dati",
    link: "AssistenzaConsulenza_Backup",
    // img_src: require("@/assets/imgs/data_backup.svg"),
    img_src: require("@/assets/imgs/pages/backup2.png"),
    img_alt: "Icona backup",
  },
  {
    // title: "Cloud<br/>&nbsp;",
    title: "Cloud<br/>&nbsp;",
    menu_item: "Cloud",
    // subtitle: "Cloud",
    link: "AssistenzaConsulenza_Cloud",
    // img_src: require("@/assets/imgs/cloud.svg"),
    img_src: require("@/assets/imgs/pages/cloud2.png"),
    img_alt: "Icona cloud",
  },
  {
    title: "Sicurezza<br/>&nbsp;",
    menu_item: "Sicurezza",

    // subtitle: "Sicurezza",
    link: "AssistenzaConsulenza_Sicurezza",
    // img_src: require("@/assets/imgs/sicurezza.svg"),
    img_src: require("@/assets/imgs/pages/security3.png"),
    img_alt: "Icona sicurezza",
  },
];

export default {
  metaInfo: {
    title: "Servizi di assistenza e consulenza informatica",
    meta: [
      {
        name: "description",
        content:
          "Scopri le soluzioni informatiche e i servizi dedicati alle aziende e agli utenti privati.",
      },
      {
        name: "robots",
        content: "index",
      },
    ],
  },

  // metaInfo: {
  //   title: "Servizi offerti",
  //   meta: [
  //     {
  //       name: "description",
  //       content: "Alcune tipologie di servizi offerti ai nostri clienti.",
  //     },
  //   ],
  // },
  name: "Home",
  components: {
    cardService,
    cardService2,
  },
  methods: {
    aos_effect(index) {
      if (index % 2 == 1) {
        return "fade-left";
      } else {
        return "fade-right";
      }
    },
  },
  props: ["what"],
  data: () => ({
    services: services_assistenza_consulenza,
  }),
  created() {},
  mounted() {
    // analytics.logEvent("openHome");
  },
  computed: {},
  watch: {},
};
</script>


<style scoped>
</style>


