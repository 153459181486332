<template>
  <div class="white--text cookie-page">
      <span class="cookie-section">Informativa ex art. 13 D.lgs. 196/2003</span>
      <span class="cookie-body">
        La presente “Privacy & Cookie Policy” (la nostra politica relativa alla
        privacy del sito e all’uso di cookie) viene applicata al trattamento
        informatico di dati di questo sito Internet, compresi i relativi
        possibili sottoddomini ma non anche per altri siti web eventualmente
        consultabili dall’utente tramite appositi link (siti di terze parti).
      </span>
      <span class="cookie-section">Titolare del trattamento</span>
      <span class="cookie-body">
        Il titolare del trattamento è l’azienda AVMSquare di Alessandro Vittorio
        Mendolia ed il responsabile dei trattamenti è il sig. Alessandro
        Vittorio Mendolia. A tale responsabile ci si potrà rivolgere scrivendo
        all’indirizzo a.mendoliaETavmsquare.com per l’esercizio dei diritti di
        cui all’art.7 D.Lgs.196/2003 di seguito riportato.
      </span>
      <span class="cookie-section"
        >Decreto Legislativo n.196/2003, Art. 7 – Diritto di accesso ai dati
        personali ed altri diritti</span
      >
      <span class="cookie-body">
        <ol>
          <li>
            L’interessato ha diritto di ottenere la conferma dell’esistenza o
            meno di dati personali che lo riguardano, anche se non ancora
            registrati, e la loro comunicazione in forma intelligibile.
          </li>
          <ol>
            <li>dell’origine dei dati personali;</li>
            <li>delle finalità e modalità del trattamento;</li>
            <li>
              della logica applicata in caso di trattamento effettuato con
              l’ausilio di strumenti elettronici;
            </li>
            <li>
              degli estremi identificativi del titolare, dei responsabili e del
              rappresentante designato ai sensi dell’articolo 5, comma 2;
            </li>
            <li>
              dei soggetti o delle categorie di soggetti ai quali i dati
              personali possono essere comunicati o che possono venirne a
              conoscenza in qualità di rappresentante designato nel territorio
              dello Stato, di responsabili o incaricati.
            </li>
          </ol>
          <li>L’interessato ha diritto di ottenere:</li>
          <ol>
            <li>
              l’aggiornamento, la rettificazione ovvero, quando vi ha interesse,
              l’integrazione dei dati;
            </li>
            <li>
              la cancellazione, la trasformazione in forma anonima o il blocco
              dei dati trattati in violazione di legge, compresi quelli di cui
              non è necessaria la conservazione in relazione agli scopi per i
              quali i dati sono stati raccolti o successivamente trattati;
            </li>
            <li>
              l’attestazione che le operazioni di cui alle lettere a) e b) sono
              state portate a conoscenza, anche per quanto riguarda il loro
              contenuto, di coloro ai quali i dati sono stati comunicati o
              diffusi, eccettuato il caso in cui tale adempimento si rivela
              impossibile o comporta un impiego di mezzi manifestamente
              sproporzionato rispetto al diritto tutelato.
            </li>
          </ol>
          <li>L’interessato ha diritto di opporsi, in tutto o in parte:</li>
          <ol>
            <li>
              per motivi legittimi al trattamento dei dati personali che lo
              riguardano, ancorché pertinenti allo scopo della raccolta;
            </li>
            <li>
              al trattamento di dati personali che lo riguardano a fini di invio
              di materiale pubblicitario o di vendita diretta o per il
              compimento di ricerche di mercato o di comunicazione commerciale.
            </li>
          </ol>
        </ol>
      </span>

      <span class="cookie-section"> Modalità del trattamento </span>
      <span class="cookie-body">
        Il trattamento viene effettuato attraverso strumenti informatici e
        telematici e/o manualmente (ad. es. su supporto cartaceo) per il tempo
        strettamente necessario a conseguire gli scopi per i quali i dati sono
        stati raccolti e comunque, in conformità alle disposizioni normative
        vigenti in materia.
      </span>

      <span class="cookie-section">
        Facoltatività del conferimento dei dati
      </span>
      <span class="cookie-body">
        A parte quanto specificato per i dati di navigazione, gli
        utenti/visitatori sono liberi di fornire i propri dati personali. Il
        loro mancato conferimento può comportare unicamente l’impossibilità di
        ottenere quanto richiesto.
      </span>

      <span class="cookie-section"> Dati di Navigazione </span>

      <span class="cookie-body">
        I sistemi informatici e le procedure software preposte al funzionamento
        del presente sito web acquisiscono, nel corso del loro normale
        esercizio, alcuni dati personali che sono trasmessi implicitamente
        nell’uso dei protocolli di comunicazione di internet. Si tratta di
        informazioni, che per loro natura, non sono raccolte per essere
        associate immediatamente ad interessati identificati, ma che per loro
        stessa natura potrebbero, tramite elaborazioni o associazioni con dati
        detenuti da terzi, permettere di identificare gli utenti/visitatori del
        sito. In questa categoria di dati rientrano gli “indirizzi IP” o i nomi
        a dominio dei computer utilizzati dagli utenti che si collegano al sito,
        gli indirizzi in rotazione URL (Uniform Resource Identifier), ecc.).
        <br />
        Questi dati sono eventualmente utilizzati a soli fini statistici (e
        quindi anonimi) per analizzare le visite/uso del sito e per controllarne
        il corretto funzionamento. Tali dati sono conservati dal Titolare del
        sito per il periodo strettamente necessario al trattamento e comunque in
        conformità alle vigenti disposizioni normative in materia.
      </span>
      <span class="cookie-section"> Cookie </span>
      <span class="cookie-body">
        I cookie sono file informatici o dati parziali che possono venire
        salvati sul vostro computer (o altri dispositivi abilitati alla
        navigazione su internet, per esempio smartphone o tablet) quando
        visitate un sito. Di solito un cookie contiene il nome del sito internet
        dal quale il cookie stesso proviene, la “durata vitale” del cookie
        (ovvero per quanto tempo rimarrà sul vostro dispositivo), ed un valore,
        che di regola è un numero unico generato in modo casuale.
        <br />
        A tale riguardo la normativa individua due macro-categorie: cookie
        “tecnici” e cookie “di profilazione”.
        <br />
        - Cookie tecnici.
        <br />
        I cookie tecnici sono quelli utilizzati al solo fine di “effettuare la
        trasmissione di una comunicazione su una rete di comunicazione
        elettronica, o nella misura strettamente necessaria al fornitore di un
        servizio della società dell’informazione esplicitamente richiesto
        dall’abbonato o dall’utente a erogare tale servizio” (cfr. art. 122,
        comma 1, del Codice). Essi non vengono utilizzati per scopi ulteriori e
        sono normalmente installati direttamente dal titolare o gestore del sito
        web. Possono essere suddivisi in cookie di navigazione o di sessione,
        che garantiscono la normale navigazione e fruizione del sito web
        (permettendo, ad esempio, di realizzare un acquisto o autenticarsi per
        accedere ad aree riservate); cookie analytics, assimilati ai cookie
        tecnici laddove utilizzati direttamente dal gestore del sito per
        raccogliere informazioni, in forma aggregata, sul numero degli utenti e
        su come questi visitano il sito stesso; cookie di funzionalità, che
        permettono all’utente la navigazione in funzione di una serie di criteri
        selezionati (ad esempio, la lingua, i prodotti selezionati per
        l’acquisto) al fine di migliorare il servizio reso allo stesso. Per
        l’installazione di tali cookie non è richiesto il preventivo consenso
        degli utenti, mentre resta fermo l’obbligo di dare l’informativa ai
        sensi dell’art. 13 del Codice, che il gestore del sito, qualora utilizzi
        soltanto tali dispositivi, potrà fornire con le modalità che ritiene più
        idonee.
        <br />
        <br />
        - Cookie di profilazione
        <br />
        I cookie di profilazione sono volti a creare profili relativi all’utente
        e vengono utilizzati al fine di inviare messaggi pubblicitari in linea
        con le preferenze manifestate dallo stesso nell’ambito della navigazione
        in rete. In ragione della particolare invasività che tali dispositivi
        possono avere nell’ambito della sfera privata degli utenti, la normativa
        europea e italiana prevede che l’utente debba essere adeguatamente
        informato sull’uso degli stessi ed esprimere così il proprio valido
        consenso.
        <br />
        Ad essi si riferisce l’art. 122 del Codice laddove prevede che
        “l’archiviazione delle informazioni nell’apparecchio terminale di un
        contraente o di un utente o l’accesso a informazioni già archiviate sono
        consentiti unicamente a condizione che il contraente o l’utente abbia
        espresso il proprio consenso dopo essere stato informato con le modalità
        semplificate di cui all’articolo 13, comma 3” (art. 122, comma 1, del
        Codice).
        <br />
        Noi utilizziamo i soli cookie tecnici per rendere l’uso dei nostri siti
        più semplice e per meglio adattarli ai vostri interessi e bisogni. I
        cookie tecnici ci aiutano anche a velocizzare le vostre future
        esperienze ed attività sui nostri siti. Inoltre, usiamo i cookie tecnici
        per compilare statistiche anonime aggregate che ci consentono di capire
        come le persone usano i nostri siti e per aiutarci a migliorare la
        struttura ed i contenuti proposti. Non siamo in grado di identificarvi
        personalmente attraverso queste informazioni.
        <br />
        Come previsto dalla normativa vigente in materia di privacy, per
        l’installazione di tali cookie non è richiesto il preventivo consenso.
        Naturalmente è possibile di bloccare l’installazione dei cookie come
        indicato dal manuale dell’utente del browser di navigazione utlizzato.
        Indichiamo di seguito come reperire le istruzioni fornite dai produttori
        dei browser di navigazione più diffusi per eliminarli:
        <br /><br />
        Explorer: <a href="https://support.microsoft.com/en-us/kb/196955/it Chrome:
        https://support.google.com/chrome/answer/95647?hl=it"> https://support.microsoft.com/en-us/kb/196955/it Chrome:
        https://support.google.com/chrome/answer/95647?hl=it</a> 
        <br>
         Firefox:
         <a href="https://support.mozilla.org/it/kb/Attivare%20e%20disattivare%20i%20cookie">https://support.mozilla.org/it/kb/Attivare%20e%20disattivare%20i%20cookie</a>
        <br>

        Safari: <a href="https://support.apple.com/kb/PH17191?locale=it_IT Opera:
        http://help.opera.com/Windows/10.00/it/cookies.html">https://support.apple.com/kb/PH17191?locale=it_IT Opera:
        http://help.opera.com/Windows/10.00/it/cookies.html</a> 
        <br /><br />
        Modificando le impostazioni del browser è possibile gestire,
        disabilitare e cancellare tutte le tipologie di cookie.
        <br />
        Tale blocco indiscriminato della ricezione di tutti i cookie, inclusi
        quelli tecnici, senza prevedere una specifica eccezione potrebbe portare
        a non essere più in grado di navigare sul sito o di usufruire in tutto o
        in parte delle sue funzionalità. Inoltre, cancellando tutti i cookie dal
        browser, anche i cookie tecnici potrebbero essere rimosse anche le
        preferenze di navigazione impostate che saranno nuovamente richieste in
        successive consultazioni.
      </span>

      <span class="cookie-section">Cookie di terze parti</span>

      <span class="cookie-body">
        Il sito fa uso per la fornitura di taluni servizi/opzioni di terze
        parti, non coperti dalla presente informativa, che possono altresì
        rilasciare cookie sul vostro dispositivo, per identificare questi cookie
        prima che vengano utilizzati in modo da permettervi di decidere se
        desiderate accettarli o meno alleghiamo la seguente tabella contenente i
        collegamenti alle singole informative:
        <div class="d-flex justify-center">
          <table
            style="background: gray; width: 100%; border-collapse: collapse"
          >
            <thead>
              <tr>
                <td
                  style="border-style: solid; border-width: 1px; padding: 10px"
                >
                  TERZA PARTE CHE CONSERVA LE INFORMAZIONI DELL’UTENTE
                </td>
                <td
                  style="border-style: solid; border-width: 1px; padding: 10px"
                >
                  LINK ALL’INFORMATIVA DELLA TERZA PARTE
                </td>
                <td
                  style="border-style: solid; border-width: 1px; padding: 10px"
                >
                  LINK AL MODULO DI CONSENSO DELLA TERZA PARTE
                </td>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td
                  style="border-style: solid; border-width: 1px; padding: 10px"
                >
                  Google Analytics
                </td>
                <td
                  style="border-style: solid; border-width: 1px; padding: 10px"
                >
                  <a href="http://www.google.com/intl/it/policies/privacy/"
                    >LINK</a
                  >
                </td>
                <td
                  style="border-style: solid; border-width: 1px; padding: 10px"
                >
                  <a href="http://tools.google.com/dlpage/gaoptout">LINK</a>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        I link sopra indicati possono essere soggetti a variazioni del tempo che
        non possono essere recepite e aggiornate in tempo reale dal sito. Le
        stesse informazioni sono, comunque, facilmente reperibili utilizzando un
        motore di ricerca od anche consultando il sito:
        www.youronlinechoices.com/it
      </span>

      <span class="cookie-section">
        Dati forniti volontariamente dagli utenti
      </span>

      <span class="cookie-body">
        Qualora gli utenti/visitatori, collegandosi a questo sito, inviino
        propri dati personali per accedere a determinati servizi, ovvero per
        effettuare richieste in posta elettronica, ciò comporta l’acquisizione
        da parte del nostro sito dell’indirizzo del mittente e/o di altri
        eventuali dati personali che verranno trattati esclusivamente per
        rispondere alla richiesta, ovvero per la fornitura del servizio. I dati
        personali forniti dagli utenti/visitatori verranno comunicati a terzi
        solo nel caso in cui la comunicazione sia necessaria per ottemperare
        alle richieste degli utenti/visitatori medesimi.
      </span>
    <div style="height: 250px"></div>
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
.cookie-page {
  text-shadow: 2px 2px 2px black;
  margin:2rem
}
.cookie-section {
  font-size: 2rem;
  font-weight: bold;
  display: block;
}
</style>